import * as React from "react";
import { Link } from 'gatsby';
import * as styles from './Gallery.module.css';
import data from '../../assets/data/data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

export default function Gallery({ type }) {
    return (
        <div className={styles.gallery}>
            {data[type].map(el => (
                <Link to={el.url} 
                    className={type === "build" ? styles.galleryShowcaseContainer : styles.galleryImageContainer} 
                    key={el.title}>
                    
                    {/* for the inspiration page, there are no images */}
                    {type === "inspiration" && 
                        <div className={styles.quoteContainer}>
                            <span className={styles.quote}>{el.quote}</span>
                        </div>
                    }

                    {type !== "inspiration" &&
                        <>
                            <img className={styles.galleryImage} src={el.img} alt={el.title}/>
                            {el.url && (
                                <div className={styles.infoFlag}>
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div> 
                            )}
                        </>
                    }

                    <p className={styles.text}>
                        {el.title}
                        <p className={styles.subtext}>
                            {el.description}
                        </p>
                    </p>
                </Link>
            ))}
        </div>
    )
};